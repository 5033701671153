import Decimal from 'decimal.js';
import 'intl';
import 'intl/locale-data/jsonp/en';

export const formatNumber = (value: string | Decimal, decimalPlaces = 8) => {
    const valueAsDecimal = new Decimal(value).toDecimalPlaces(decimalPlaces, Decimal.ROUND_FLOOR);

    if (valueAsDecimal.lessThan(new Decimal('0.00001'))) {
        return {
            value: new Decimal('0'),
            label: '0',
        };
    }

    return {
        value: valueAsDecimal,
        label: new Intl.NumberFormat('en-US', {
            maximumFractionDigits: decimalPlaces,
        }).format(valueAsDecimal.toNumber()),
    };
};

export const formatForTrading = (value: string | Decimal, decimalPlaces = 8) => {
    Decimal.set({ rounding: Decimal.ROUND_FLOOR });

    if (new Decimal(value).lessThan(new Decimal('0.00001'))) {
        return {
            value: new Decimal('0'),
            label: '0',
        };
    }

    const rounded = new Decimal(value ? new Decimal(value).toFixed(decimalPlaces) : '0');

    Decimal.set({ defaults: true });

    const stringValue = rounded.toString();

    const trimmed =
        stringValue.length > 8 && rounded.lessThanOrEqualTo(new Decimal('9999999'))
            ? stringValue.substring(0, 8)
            : stringValue;

    if (trimmed.endsWith('.')) {
        return {
            value: new Decimal(trimmed),
            label: `${trimmed}00`,
        };
    }

    if (!trimmed.includes('.')) {
        return {
            value: new Decimal(trimmed),
            label: `${trimmed}.00`,
        };
    }

    return {
        value: new Decimal(trimmed),
        label: trimmed,
    };
};

export const formatForDisplay = (value: string | Decimal, decimalPlaces = 8) => {
    Decimal.set({ rounding: Decimal.ROUND_FLOOR });

    const rounded = new Decimal(value ? new Decimal(value).toFixed(decimalPlaces) : '0');

    Decimal.set({ defaults: true });

    return new Intl.NumberFormat('en-US', { maximumSignificantDigits: Math.min(8, rounded.toString().length) }).format(
        parseFloat(rounded.toString())
    );
};
