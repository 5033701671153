/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */

import axios from 'axios';
import * as moment from 'moment';

export const buildCsv = (transactions: any) => {
    const headers = 'Type,Currency,Product,Amount From,Amount To,Amount,Exchange Rate,Fee,Info,Currency Pair,Timestamp';

    const hiddenElement = document.createElement('a');
    const href = encodeURI(
        `${headers}\n${transactions
            .map(
                (row: any) =>
                    `${row.type.toUpperCase()},${row.currency_label},${row.product_label || ''},${
                        row.amount_from || 0
                    },${row.amount_to},${row.amount},${row.exchange_rate || ''},${row.fee || 0},${row.type_label},${
                        row.currency_pair
                    },${moment.utc(row.created_at).format('DD-MM-YYYY HH:mm:ss')}`
            )
            .join('\n')}`
    );

    hiddenElement.href = `data:text/csv;charset=utf-8,${href}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'AqruTransactions.csv';

    return hiddenElement.click();
};

export const buildKoinlyCsv = async (transactions: any) => {
    const headers = `Date, Sent Amount, Sent Currency, Received Amount, Received Currency, Fee Amount, Fee Currency, Label`;
    const koinlyLabel = 'Loan Interest';

    const hiddenElement = document.createElement('a');

    const {
        data: { product_map, currencies },
    } = await axios({
        url: `https://cdn.accru.finance/copy/currency-config.json?cache=${new Date().getTime()}`,
    });

    const href = encodeURI(
        `${headers}\n${transactions
            // no need to factor in like for like transactions since they dont incur a fee and dont really change hands
            .filter((row: any) => !['BTC/BTC', 'ETH/ETH', 'USDC/USDC'].includes(row.currency_pair))
            .map((row: any) => {
                const timestamp = moment.utc(row.created_at).format('DD-MM-YYYY HH:mm:ss');

                const currency = currencies.find((item: any) => item.id === row.currency)?.label;

                const currencyFrom = currencies.find((item: any) => item.id === row.currency_from)?.label;
                const currencyTo = currencies.find((item: any) => item.id === row.currency_to)?.label;

                const product = product_map[row.product];

                if (row.type === 'deposit' || row.type === 'Interest Deposit') {
                    if (row.type_label === 'Deposit Interest') {
                        return `${timestamp},,,${row.amount},${row.currency},,,${koinlyLabel}`;
                    }
                    return `${timestamp},,,${row.amount},${currency},,`;
                }

                if (row.type === 'withdrawal') {
                    return `${timestamp},-${row.amount},${currency},,,${row.fee},,`;
                }

                let amountFrom = row.amount_from || 0;
                let amountTo = row.amount_to || 0;

                if (
                    row.type_label === 'Free Credit' &&
                    ['GBP/USDC', 'EUR/USDC', 'USDT/USDC'].includes(row.currency_pair)
                ) {
                    amountFrom = row.amount;
                    amountTo = row.amount;
                }
                if (row.type === 'exchange') {
                    return `${timestamp},${amountFrom},${currencyFrom},${amountTo},${currencyTo},${row.fee},${currencyTo},,`;
                }

                if (row.trade_type === 'BUY') {
                    // free cash money doesn't have quote value
                    if (row.type_label === 'Free Credit' && ['GBP/USDC', 'EUR/USDC'].includes(row.currency_pair)) {
                        amountFrom = 10;
                    }

                    return `${timestamp},${amountFrom},${currency},${amountTo},${product},${row.fee},${product},`;
                }

                return `${timestamp},${amountFrom},${product},${amountTo},${currency},${row.fee},${currency},`;
            })
            .join('\n')}`
    );

    hiddenElement.href = `data:text/csv;charset=utf-8,${href}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'KoinlyAqruTransactions.csv';

    return hiddenElement.click();
};
